<template>
  <div class="admin-store">
    <div class="title">
      <span class="s">店铺列表</span>
    </div>
    <div class="search-cell">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        label-width="74px"
      >
        <el-form-item label="店铺名称：">
          <el-input
            style="width: 160px"
            v-model="formInline.name"
            size="mini"
            placeholder="店铺名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="店铺账号：">
          <el-input
            style="width: 160px"
            v-model="formInline.username"
            size="mini"
            placeholder="店铺账号"
          ></el-input>
        </el-form-item>

        <el-form-item label="所属学校：">
          <el-select
            style="width: 160px"
            size="mini"
            v-model="formInline.school_id"
            placeholder="全部"
            @change="getClassLt"
          >
            <el-option label="全部" value="0"></el-option>
            <el-option
              :label="item.school_name"
              :value="item.id"
              v-for="item in schoolList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属班级：">
          <el-select
            style="width: 160px"
            size="mini"
            v-model="formInline.class_id"
            placeholder="全部"
          >
            <el-option label="全部" value="0"></el-option>
            <el-option
              :label="item.class_name"
              :value="item.id"
              v-for="item in classList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间：" style="margin-top: -10px">
          <el-date-picker
            size="mini"
            v-model="formInline.time"
            type="datetimerange"
            @change="handleTimeChange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item style="margin-top: -10px">
          <el-button
            size="mini"
            type="primary"
            @click="
              page = 1;
              getStoreList();
            "
            >查询</el-button
          >
          <el-button size="mini" plain @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tab">
      <el-table
        size="small "
        border
        :data="tableData.list"
        v-loading="loading"
        style="width: 100%; text-algin: center"
        :header-cell-style="{ background: '#F2F2F2', 'border-color': '#000' }"
        :row-style="{ 'border-color': '#000' }"
        :cell-style="{ 'border-color': '#000' }"
        :header-row-style="{ 'border-color': '#000' }"
      >
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="id"
          label="序号"
          width="80"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="shop_name"
          label="店铺名称"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="member_name"
          label="店铺账号"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="school_name"
          label="所属学校"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="class_name"
          label="所属班级"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="time"
          label="创建时间"
        >
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{
              scope.row.time | formatTimeS("all")
            }}</span>
          </template>
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="school_name"
          label="操作"
        >
          <template slot-scope="scope">
            <span class="btn" @click="handleLook(scope.row)">查看</span>
            <!-- <span class="btn" @click="handleDel">删除</span> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        small
        style="margin-top: 20px"
        background
        :page-size="page_size"
        layout="prev, pager, next"
        :total="tableData.total"
        :current-page="page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { shopList, getSchoolList, getClassList } from "@/api/admin.js";
export default {
  data() {
    return {
      formInline: {
        name: "",
        username: "",
        school_id: "",
        class_id: "",
        start_time: "",
        end_time: "",
        time: [],
      },
      tableData: {
        list: [],
      },
      page: 1,
      page_size: 10,
      schoolList: [],
      classList: [],
      loading: true,
    };
  },
  created() {
    this.getCampusList();
    this.getClassLt();
    this.getStoreList();
  },
  methods: {
    getStoreList() {
      this.loading = true;
      shopList({
        ...this.formInline,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.tableData = res.data;
        }
      });
    },
    handleReset() {
      this.formInline = {
        name: "",
        username: "",
        school_id: "",
        class_id: "",
        start_time: "",
        end_time: "",
        time: [],
      };
      this.page = 1;
      this.getStoreList();
    },
    getCampusList() {
      getSchoolList().then((res) => {
        if (res.code == 1) {
          this.schoolList = res.data;
        }
      });
    },
    getClassLt() {
      this.formInline.class_id = "";
      getClassList({
        school_id: this.formInline.school_id,
      }).then((res) => {
        this.classList = res.data;
      });
    },
    handleTimeChange() {

      this.formInline.start_time = parseInt(
        this.formInline.time[0].getTime() / 1000
      );
      this.formInline.end_time = parseInt(
        this.formInline.time[1].getTime() / 1000
      );
    },
    pageChange(index) {
      this.page = index;
      this.getStoreList();
    },
    handleLook(val) {
      sessionStorage.ADMIN_SHOPID = val.id;
      this.$router.push("adminStoreInfo");
    },
  },
};
</script>
<style lang="less" >
.admin-store {
  background: #fff;
  padding: 20px;
  .title {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    .s {
      font-size: 14px;
      color: #ee4d2d;
      border-bottom: 2px solid #ee4d2d;
      padding: 0 10px;
      padding-bottom: 10px;
    }
  }
  .search-cell {
    padding: 10px 0;
  }
  .tab {
    .btn {
      font-size: 12px;
      cursor: pointer;
      margin: 0 10px;
    }
    .btn:hover {
      border-bottom: 1px solid #000;
    }
  }
}
.el-form-item__label {
  font-size: 12px;
}
// .el-table--border .el-table__cell {
//   border-color: #000;
// }
</style>